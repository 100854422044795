import React from 'react'

import ProductButtons from './ProductButtons'
import ProductGallery from './ProductGallery'
import IconCalendarGrey from '../../assets/images/icons/icon_calendar_grey.png'

const ProductDetailRoundtrip = props => {
  const { roundtrip, forms, destinationLabel, pageContext } = props
  const { images } = roundtrip

  // console.log('ProductDetailRoundtrip', roundtrip)

  const minPrice = roundtrip.price

  // const title = roundtrip.seo?.h1 || roundtrip.title
  const getProductTitle = () => {
    let title = roundtrip.seo?.h1 || ''
    if (roundtrip?.from) {
      title = `${roundtrip.title} ab ${roundtrip.from}`
    } else if (roundtrip?.location) {
      title = `${roundtrip.title} ab ${roundtrip.location}`
    }
    return title
  }

  const daysAndNights = `${roundtrip.days} Tage / ${roundtrip.nights} Nächte`

  let location = ''
  if (roundtrip.from || roundtrip.to) {
    if (roundtrip.from === roundtrip.to) {
      location = `ab/bis ${roundtrip.from}`
    } else {
      location = `ab ${roundtrip.from} bis ${roundtrip.to}`
    }
  }

  // get roundtrip package information - from latest version
  let packageInfo = null
  const roundtripVersions = roundtrip?.versions
  if (roundtripVersions.length > 0) {
    const latestVersion = roundtripVersions[roundtripVersions.length - 1]
    if (latestVersion?.package) {
      packageInfo = latestVersion.package
    }
  }

  return (
    <>
      <div className='content-element story-group page-intro'>
        <h1>{getProductTitle()}</h1>
        <div dangerouslySetInnerHTML={{ __html: roundtrip.intro }} />
      </div>

      <div className='content-element bg-white'>
        <div className='product-detail'>
          <div className='row'>
            <div className='col-sm-4'>
              <div className='product-info mobile-content-wrapper'>
                <div className='header'>
                  <strong>{getProductTitle()}</strong>
                  <div className='mt-1'>
                    {minPrice ? (
                      <div>
                        ab CHF {minPrice}.– /{' '}
                        <span className='price-period'>pro Person</span>
                      </div>
                    ) : (
                      'Preis auf Anfrage'
                    )}
                  </div>
                  <div className='dots-divider mt-3' />
                </div>

                <div className='location mt-3'>{location}</div>

                {roundtrip.highlights &&
                  Array.isArray(roundtrip.highlights) &&
                  roundtrip.highlights.length && (
                    <div className='desc roundtrip-higlights mt-3'>
                      Highlights: <br />
                      {roundtrip.highlights.map((highlight, index) => (
                        <div key={index}>- {highlight}</div>
                      ))}
                    </div>
                  )}

                <div className='calendar mt-3'>
                  <img
                    src={IconCalendarGrey}
                    width={20}
                    className='mr-1'
                    alt='Icon calendar'
                    title='Icon calendar'
                    loading='lazy'
                  />{' '}
                  {daysAndNights}
                </div>

                <ProductButtons
                  forms={forms}
                  productName={roundtrip.title}
                  pageContext={pageContext}
                  onlyFirstButton
                  destinationLabel={destinationLabel}
                />
              </div>
            </div>

            <div className='col-sm-8 gallery-col'>
              <div className='gallery-roundtrip row'>
                <ProductGallery images={images} />
              </div>
            </div>
          </div>

          <div className='product-description mobile-content-wrapper'>
            <div className='no-pb'>
              <p>
                <strong>Reiseprogramm</strong>
              </p>
              <div className='separator separator-left'></div>
            </div>

            <div className='row'>
              <div className='col-sm-8'>
                <div className='columned columns-2 product-program'>
                  {roundtrip.program.map((programItem, index) => (
                    <div key={index}>
                      <strong>
                        {programItem.day}. Tag{' '}
                        {
                          <span
                            dangerouslySetInnerHTML={{
                              __html: programItem.title
                            }}
                          ></span>
                        }
                      </strong>
                      <p
                        dangerouslySetInnerHTML={{ __html: programItem.text }}
                      />
                    </div>
                  ))}
                  {/* display roundtrip package information - after Reiseprogramm content */}
                  {packageInfo && (
                    <>
                      <div className='columned columns-1 product-package-info-container'>
                        <div
                          className='product-package-info'
                          dangerouslySetInnerHTML={{
                            __html: packageInfo
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                {/* display roundtrip package information - in a separate container */}
                {/* {packageInfo && (
                  <>
                    <div className='columned columns-2 product-package-info-wrapper'>
                      <div
                        className='product-package-info'
                        dangerouslySetInnerHTML={{
                          __html: packageInfo
                        }}
                      />
                    </div>
                  </>
                )} */}
              </div>
              <div className='col-sm-4'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: roundtrip.intro2
                  }}
                />
                <p>
                  <strong>Preise</strong>
                  <br />
                  {daysAndNights}{' '}
                  {minPrice ? (
                    <>
                      ab CHF {minPrice}.– /{' '}
                      <span className='price-period'>pro Person</span>
                    </>
                  ) : (
                    'Preis auf Anfrage'
                  )}
                </p>

                <ProductButtons
                  forms={forms}
                  productName={roundtrip.title}
                  pageContext={pageContext}
                  showOnlyFirstButton
                  destinationLabel={destinationLabel}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductDetailRoundtrip
